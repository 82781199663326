/* eslint-disable import/no-internal-modules */

import { createIconComponent } from './BaseIcon';
import AlertCircleFaded from './svg/alert-circle-faded.svg?icon';
import Back from './svg/back.svg?icon';
import ExternalLinkRounded from './svg/external-link-rounded.svg?icon';
import HipChat from './svg/hip-chat.svg?icon';
import IndicatorFlag from './svg/indicator-flag.svg?icon';
import LinkRequest from './svg/link-request.svg?icon';
import QuestionMarkFilled from './svg/question-mark-filled.svg?icon';
import QuestionMarkOutline from './svg/question-mark-outline.svg?icon';
import SuccessCircleFaded from './svg/success-circle-faded.svg?icon';
import Tools from './svg/tools.svg?icon';
import UploadFaded from './svg/upload-faded.svg?icon';
import Verified from './svg/verified.svg?icon';
/**
 * Updated icons beneath
 */
import Add from './svg/add.svg?icon'; // updated
import AddCircleFilled from './svg/add-circle-filled.svg?icon'; // updated
import AlertCircle from './svg/alert-circle.svg?icon'; // updated
import ArrowDownCircleFilled from './svg/arrow-down-circle-filled.svg?icon'; // Updated
import ArrowRight from './svg/arrow-right.svg?icon'; // updated
import Bulb from './svg/bulb.svg?icon'; // updated
import CaretLeft from './svg/caret-left.svg?icon'; // updated
import CaretLeftCircle from './svg/caret-left-circle.svg?icon'; // updated
import CaretLeftCircleFilled from './svg/caret-left-circle-filled.svg?icon'; // updated
import CaretRight from './svg/caret-right.svg?icon'; // updated
import CaretRightCircle from './svg/caret-right-circle.svg?icon'; // updated
import CaretRightCircleFilled from './svg/caret-right-circle-filled.svg?icon'; // updated
import CaretDown from './svg/caret-down.svg?icon'; // updated
import CaretDownCircle from './svg/caret-down-circle.svg?icon'; // updated
import CaretDownCircleFilled from './svg/caret-down-circle-filled.svg?icon'; // updated
import CaretUp from './svg/caret-up.svg?icon'; // updated
import CaretUpCircle from './svg/caret-up-circle.svg?icon'; // updated
import CaretUpCircleFilled from './svg/caret-up-circle-filled.svg?icon'; // updated
import Close from './svg/close.svg?icon'; // updated
import ComplianceCheck from './svg/compliance-check.svg?icon'; // updated
import Copy from './svg/copy.svg?icon'; // updated
import Corporate from './svg/corporate.svg?icon'; // updated
import GridFilled from './svg/grid-filled.svg?icon'; // updated
import Dapps from './svg/dapps.svg?icon'; // updated
import Directory from './svg/directory.svg?icon'; // updated
import Edit from './svg/edit.svg?icon'; // updated
import ExternalLink from './svg/external-link.svg?icon'; // updated
import Heart from './svg/heart.svg?icon'; //updated
import Hamburger from './svg/hamburger.svg?icon'; // updated
import HelpCircle from './svg/help-circle.svg?icon'; // updated
import HistoryLinear from './svg/history-linear.svg?icon'; // updated
import InfoCircle from './svg/info-circle.svg?icon'; // updated
import Key from './svg/key.svg?icon'; // updated
import List from './svg/list.svg?icon'; // updated
import Lightning from './svg/lightning.svg?icon'; // updated
import Lock from './svg/lock.svg?icon'; // updated
import LockFailed from './svg/lock-failed.svg?icon'; // updated
import MagicWand from './svg/magic-wand.svg?icon'; // updated
import NetworkActivity from './svg/network-activity.svg?icon'; // updated
import OperationAllowAddress from './svg/operation-allow-address.svg?icon'; // updated
import OperationBurn from './svg/operation-burn.svg?icon'; // updated
import OperationDisableAddress from './svg/operation-disable-address.svg?icon'; // updated
import OperationMint from './svg/operation-mint.svg?icon'; // updated
import OperationTransferIn from './svg/operation-transfer-in.svg?icon'; // updated
import OperationTransferOut from './svg/operation-transfer-out.svg?icon'; // updated
import OperationTransferOutCircle from './svg/operation-transfer-out-circle.svg?icon'; // updated
import More from './svg/more.svg?icon'; // updated
import Pencil from './svg/pencil.svg?icon'; // updated
import Phone from './svg/phone.svg?icon'; // updated
import Remove from './svg/remove.svg?icon'; // updated
import RemoveCircleFilled from './svg/remove-circle-filled.svg?icon'; // updated
import RemoveCircle from './svg/remove-circle.svg?icon'; // updated
import Report from './svg/report.svg?icon'; // updated
import Reshare from './svg/reshare.svg?icon'; // updated
import Return from './svg/return.svg?icon'; // updated
import Rocket from './svg/rocket.svg?icon'; // updated
import Search from './svg/search.svg?icon'; // updated
import Settings from './svg/settings.svg?icon'; // updated
import Spinner from './svg/spinner.svg?icon'; // updated
import Success from './svg/success.svg?icon'; // updated
import SuccessCircleFilled from './svg/success-circle-filled.svg?icon'; // Updated
import SuccessCircle from './svg/success-circle.svg?icon'; //updated
import SignOut from './svg/sign-out.svg?icon'; // updated
import ThirdParty from './svg/third-party.svg?icon'; // updated
import TriangleFilled from './svg/triangle-filled.svg?icon'; // updated
import Unlock from './svg/unlock.svg?icon'; // updated
import UnlockFailed from './svg/unlock-failed.svg?icon'; // updated
import User from './svg/user.svg?icon'; // updated
import UserAdd from './svg/user-add.svg?icon'; // updated
import UserCog from './svg/user-cog.svg?icon'; // updated
import UserDeactivate from './svg/user-deactivate.svg?icon'; // updated
import Users from './svg/users.svg?icon'; // updated
import Vault from './svg/vault.svg?icon'; // Updated
import VaultCreate from './svg/vault-create.svg?icon'; // updated
import VaultFilled from './svg/vault-filled.svg?icon'; // updated
import VaultGovernance from './svg/vault-governance.svg?icon'; // updated
import VaultLock from './svg/vault-lock.svg?icon'; // updated
import VaultNotFound from './svg/vault-not-found.svg?icon';
import Video from './svg/video.svg?icon'; // updated
import WalletConnectConnected from './svg/wallet-connect-connected.svg?icon';
import WalletConnectNotConnected from './svg/wallet-connect-not-connected.svg?icon';

export const Icons = {
  Add: createIconComponent(Add, { v2: true }),
  AlertCircle: createIconComponent(AlertCircle, { v2: true }),
  AddCircleFilled: createIconComponent(AddCircleFilled, { v2: true }),
  AlertCircleFaded: createIconComponent(AlertCircleFaded),
  ArrowDownCircleFilled: createIconComponent(ArrowDownCircleFilled, { v2: true }),
  ArrowRight: createIconComponent(ArrowRight, { v2: true }),
  Back: createIconComponent(Back),
  Bulb: createIconComponent(Bulb, { v2: true }),
  CaretDown: createIconComponent(CaretDown, { v2: true }),
  CaretDownCircle: createIconComponent(CaretDownCircle, { v2: true }),
  CaretDownCircleFilled: createIconComponent(CaretDownCircleFilled, { v2: true }),
  CaretLeft: createIconComponent(CaretLeft, { v2: true }),
  CaretLeftCircle: createIconComponent(CaretLeftCircle, { v2: true }),
  CaretLeftCircleFilled: createIconComponent(CaretLeftCircleFilled, { v2: true }),
  CaretRight: createIconComponent(CaretRight, { v2: true }),
  CaretRightCircle: createIconComponent(CaretRightCircle, { v2: true }),
  CaretRightCircleFilled: createIconComponent(CaretRightCircleFilled, { v2: true }),
  CaretUp: createIconComponent(CaretUp, { v2: true }),
  CaretUpCircle: createIconComponent(CaretUpCircle, { v2: true }),
  CaretUpCircleFilled: createIconComponent(CaretUpCircleFilled, { v2: true }),
  Checkmark: createIconComponent(Success, { v2: true }),
  CheckIndeterminate: createIconComponent(Remove, { v2: true }),
  Close: createIconComponent(Close, { v2: true }),
  ComplianceCheck: createIconComponent(ComplianceCheck, { v2: true }),
  Copy: createIconComponent(Copy, { v2: true }),
  Corporate: createIconComponent(Corporate, { v2: true }),
  Dapps: createIconComponent(Dapps, { v2: true }),
  Edit: createIconComponent(Edit, { v2: true }),
  ExternalLink: createIconComponent(ExternalLink, { v2: true }),
  ExternalLinkRounded: createIconComponent(ExternalLinkRounded),
  Heart: createIconComponent(Heart, { v2: true }),
  GridFilled: createIconComponent(GridFilled, { v2: true }),
  Hamburger: createIconComponent(Hamburger, { v2: true }),
  HelpCircle: createIconComponent(HelpCircle, { v2: true }),
  HipChat: createIconComponent(HipChat),
  HistoryLinear: createIconComponent(HistoryLinear, { v2: true }),
  InfoCircle: createIconComponent(InfoCircle, { v2: true }),
  IndicatorFlag: createIconComponent(IndicatorFlag),
  Key: createIconComponent(Key, { v2: true }),
  LinkRequest: createIconComponent(LinkRequest),
  Lightning: createIconComponent(Lightning, { v2: true }),
  List: createIconComponent(List, { v2: true }),
  Lock: createIconComponent(Lock, { v2: true }),
  LockFailed: createIconComponent(LockFailed, { v2: true }),
  MagicWand: createIconComponent(MagicWand, { v2: true }),
  More: createIconComponent(More, { v2: true }),
  NetworkActivity: createIconComponent(NetworkActivity, { v2: true }),
  Directory: createIconComponent(Directory, { v2: true }),
  Pencil: createIconComponent(Pencil, { v2: true }),
  Phone: createIconComponent(Phone, { v2: true }),
  QuestionMarkFilled: createIconComponent(QuestionMarkFilled),
  QuestionMarkOutline: createIconComponent(QuestionMarkOutline),
  Remove: createIconComponent(Remove, { v2: true }),
  RemoveCircle: createIconComponent(RemoveCircle, { v2: true }),
  RemoveCircleFilled: createIconComponent(RemoveCircleFilled, { v2: true }),
  Report: createIconComponent(Report, { v2: true }),
  Return: createIconComponent(Return, { v2: true }),
  Rocket: createIconComponent(Rocket, { v2: true }),
  Search: createIconComponent(Search, { v2: true }),
  Settings: createIconComponent(Settings, { v2: true }),
  Spinner: createIconComponent(Spinner, { v2: true }),
  Success: createIconComponent(Success, { v2: true }),
  SuccessCircle: createIconComponent(SuccessCircle, { v2: true }),
  SuccessCircleFaded: createIconComponent(SuccessCircleFaded),
  SuccessCircleFilled: createIconComponent(SuccessCircleFilled, { v2: true }),
  SignOut: createIconComponent(SignOut, { v2: true }),
  Tools: createIconComponent(Tools),
  OperationAllowAddress: createIconComponent(OperationAllowAddress, { v2: true }),
  OperationBurn: createIconComponent(OperationBurn, { v2: true }),
  OperationDisableAddress: createIconComponent(OperationDisableAddress, { v2: true }),
  OperationMint: createIconComponent(OperationMint, { v2: true }),
  OperationReshare: createIconComponent(Reshare, { v2: true }),
  OperationTransferIn: createIconComponent(OperationTransferIn, { v2: true }),
  OperationTransferOut: createIconComponent(OperationTransferOut, { v2: true }),
  OperationTransferOutCircle: createIconComponent(OperationTransferOutCircle, { v2: true }),
  OperationCreateVault: createIconComponent(VaultCreate, { v2: true }),
  TriangleFilled: createIconComponent(TriangleFilled, { v2: true }),
  Unlock: createIconComponent(Unlock, { v2: true }),
  UnlockFailed: createIconComponent(UnlockFailed, { v2: true }),
  UploadFaded: createIconComponent(UploadFaded),
  User: createIconComponent(User, { v2: true }),
  UserAdd: createIconComponent(UserAdd, { v2: true }),
  UserCog: createIconComponent(UserCog, { v2: true }),
  UserDeactivate: createIconComponent(UserDeactivate, { v2: true }),
  Users: createIconComponent(Users, { v2: true }),
  Vault: createIconComponent(Vault, { v2: true }),
  VaultFilled: createIconComponent(VaultFilled, { v2: true }),
  VaultGovernance: createIconComponent(VaultGovernance, { v2: true }),
  VaultLock: createIconComponent(VaultLock, { v2: true }),
  VaultNotFound: createIconComponent(VaultNotFound, { v2: true }),
  Video: createIconComponent(Video, { v2: true }),
  Verified: createIconComponent(Verified),
  ThirdParty: createIconComponent(ThirdParty, { v2: true }),
  WalletConnectConnected: createIconComponent(WalletConnectConnected, { v2: true }),
  WalletConnectNotConnected: createIconComponent(WalletConnectNotConnected, { v2: true }),
};

export type IconName = keyof typeof Icons;

export function isIconName(iconName: IconName | string | undefined): iconName is IconName {
  return Icons[iconName as IconName] !== undefined;
}
